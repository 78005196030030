import React from "react";
import Icon from "../../../lds/Icon";
import Heading from "@javascript/reactComponents/lds/typography/Heading";

const ListDeleteModalSuccessBody = () => {
  return (
    <div className="status flex-col">
      <Icon name="special-success" />

      <Heading size="h2">
        Lists deleted successfully!
      </Heading>
    </div>
  );
};

export default ListDeleteModalSuccessBody;
