import Reflux from "reflux";
import createReactClass from "create-react-class";
import Container from "@javascript/reactComponents/lds/Container"
import ColleaguesListsStore from "@javascript/stores/lists/ColleagueListStore"
import ColleaguesListsActions from "@javascript/stores/actions/lists/ColleagueListsActions"
import ColleaguesListsSection from "./colleaguesLists/ColleaguesListsSection";
import CategoryButtonGroup from "./CategoryButtonGroup";
import NoLists from "./NoLists";

const ColleaguesLists = createReactClass({
  mixins: [Reflux.connect(ColleaguesListsStore)],
  componentDidMount() {
    ColleaguesListsActions.initialState({ categories: this.props.categories });
  },
  render() {
    const { lists } = this.state;

    // Loading
    if (!lists) return null;

    const letters = Object.keys(lists).sort();


    const sum = Object.values(this.state.categories).reduce(
      (acc, curr) => acc + curr,
      0
    );
    const allCategories = { All: sum, ...this.state.categories };
    const hasColleaguesLists = letters.length > 0;

    return (
      <>
        <CategoryButtonGroup
          categories={allCategories}
          selected={this.state.selectedCategory}
          onCategorySelected={ColleaguesListsActions.selectCategory}
        />

        <div style={{ marginTop: "var(--space-xLarge)" }} />

        { hasColleaguesLists ?
          <Container className="container w-1200">
            {letters.map((letter) => (
              <ColleaguesListsSection
                key={letter}
                letter={letter}
                bucket={lists[letter]}
              />
            ))}
          </Container> : <NoLists />
        }
      </>
    );
  },
});

export default ColleaguesLists;
