import { ModalBody } from "@javascript/reactComponents/lds/modal/Modal";
import ListName from "./listName";
import OrgListDisplayPanel from "./orgListDisplayPanel";
import SponsorOnlyListSwitch from "./sponsorOnlyListSwitch"

const ListModalBody = (props) => {
	var list_display;
	if(props.total_sponsors > 0) {
			list_display = <div><SponsorOnlyListSwitch  {...props} />
					<OrgListDisplayPanel {...props} />
					<ListName {...props} /></div>;
	}
	else {
			list_display = <div className="row-fluid"><div className="span12"><h4>No organizations match this search</h4></div></div>;
	}

	
	return (
    <ModalBody className="flex-col">
      <p>
        You are generating a list of the organizations mentioned in your current
        event search. Your current search is narrowed to events that involve{" "}
        <b>ALL</b> of the following...
      </p>
      <SearchDescriptionPanel search={props.query} />
      {list_display}
    </ModalBody>
  );
}

export default ListModalBody;
