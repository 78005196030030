import Button from "../../lds/Button";

export default function ListPageFooter({
  showSelectAll = false,
  currentCount,
  onLoadMore,
  onSelectAllLists,
  isAllSelected,
  footerText,
  disableLoadMore,
}) {
  const variant = disableLoadMore ? "disable" : "primary";

  let selectAllBtn = null;
  if (showSelectAll) {
    selectAllBtn = (
      <div className="footer-item select-all">
        <label htmlFor="select-all">Select all {currentCount} lists</label>
        <input
          type="checkbox"
          id="select-all"
          onClick={onSelectAllLists}
          checked={isAllSelected}
          onChange={() => {}}
        />
      </div>
    );
  }
  return (
    <div className="container w-1200 footer-container">
      <div className="footer-row">
        <div className="footer-item" />
        <div className="footer-item count-text">{footerText}</div>
        <div className="footer-item select-all">{selectAllBtn}</div>
      </div>
      <div className="footer-row">
        <div className="footer-item" />
        <div className="footer-item button">
          <Button size="medium" variant={variant} onClick={onLoadMore}>
            Load More
          </Button>
        </div>
        <div className="footer-item" />
      </div>
    </div>
  );
}
