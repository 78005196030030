import { useState, useEffect } from "react";
import Button from "../../lds/Button";
import Input from "../../lds/Input";
import Dropdown, { DropdownTrigger } from "../../lds/dropdown/Dropdown";
import DropdownList from "../../lds/dropdown/DropdownList";

export default function SearchFilters({
  searchValue,
  sortOptions,
  selectedSortOption,
  onSortOptionUpdate,
  onSearchValueUpdate,
}) {
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);

  const handleItemSelected = (item) => {
    setOpen(false);
    onSortOptionUpdate(item);
  };

  sortOptions = [...sortOptions];
  sortOptions.forEach((opt) => {
    opt.selected = opt.value === selectedSortOption.value;
  });

  useEffect(() => {
    setSearch(searchValue);
  }, [searchValue]);

  return (
    <div id="list-search-filters" className="flex-row align-start input-group-horizontal">
      <Input
        id="list_search"
        value={search}
        onValueChange={setSearch}
        onSubmit={() => onSearchValueUpdate(search)}
        size="medium"
        placeholder="Search list names"
        showLabel={false}
        leadingIcon={"search"}
        width="50%"
      />
      <Button
        size="medium"
        variant="primary"
        onClick={() => onSearchValueUpdate(search)}
      >
        Search
      </Button>

      <div className="flex-grow-1" />

      <div className="flex-row align-center">
        <Dropdown
          id="list-sort-options-dropdown"
          onDropdownClose={() => setOpen(false)}
          onItemSelected={handleItemSelected}
        >
          <DropdownTrigger>
            <Input
              id="lists_sort_options_trigger"
              readOnly
              onValueChange={() => {}}
              size="medium"
              showLabel={false}
              helperTextVariant="hidden"
              trailingIcon={"chevron-" + (open ? "up" : "down")}
              value={selectedSortOption.label}
              width="100%"
            />
          </DropdownTrigger>

          <DropdownList leadingIcon={"radio"} items={sortOptions} />
        </Dropdown>
      </div>
    </div>
  );
}
