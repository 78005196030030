import React, { useContext } from "react";
import Toast from "../../../lds/notifications/Toast";
import EntityChip from "../../../shared/chips/EntityChip";
import { DeleteModalContext } from "../ListDeleteModal";
import ScrollableList from "../ScrollableList";

import ToastBanner from "../ToastBanner";
import BannerHeading from "@javascript/reactComponents/lds/notifications/BannerHeading";
import BannerBody from "@javascript/reactComponents/lds/notifications/BannerBody";
import Banner from "@javascript/reactComponents/lds/notifications/Banner";

const ListDeleteModalPreviewBody = () => {
  const { selectedLists } = useContext(DeleteModalContext);

  const listRows = [...selectedLists.values()].map((list) => {
    return (
      <div className="name-column" key={list.id}>
        <EntityChip label={list.type_ref} size="small" />
        <p className="list-name">{list.name}</p>
      </div>
    );
  });

  return (
    <>
      <Banner variant="critical">
        <BannerHeading>This action is irreversible</BannerHeading>
        <BannerBody>
          <p>
            All the selected lists will be permanently removed and you won't be
            able to retrieve them, including the ones you've shared with your
            colleagues.
          </p>
        </BannerBody>
      </Banner>
      <ScrollableList>{listRows}</ScrollableList>
    </>
  );
};

export default ListDeleteModalPreviewBody;
