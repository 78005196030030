import PropTypes from "prop-types";

export default function Container({ children, className = "", id, style }) {
  const classNames = ["lds-container", className];
  return (
    <div id={id} className={classNames.join(" ")} style={style}>
      {children}
    </div>
  );
}

Container.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.object
};
