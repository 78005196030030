import React, {useState, createContext} from "react";
import PropTypes from "prop-types";
import ButtonGroup from "../../lds/ButtonGroup";
import ListDeleteModalBody from "./listDeleteModal/ListDeleteModalBody";
import ListDeleteModalActionsBar from "./listDeleteModal/ListDeleteModalActionsBar";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "@javascript/reactComponents/lds/modal/Modal";

const DeleteModalContext = createContext({
  onDeleteLists: (e) => {},
  cancelModal: (e) => {},
  resetCategory: (e) => {},
  selectedLists: new Map(),
  deleteState: "",
  setDeleteState: (e) => {},
  lists: [],
});

const ListDeleteModal = ({ onDeleteLists, cancelModal, resetCategory, selectedLists, lists }) => {
  const [deleteState, setDeleteState] = useState("preview");

  if (deleteState === "deleting" && selectedLists.size === 0) {
    setDeleteState("success");
  }

  return (
    <DeleteModalContext.Provider
      value={{
        onDeleteLists,
        cancelModal,
        resetCategory,
        selectedLists,
        deleteState,
        setDeleteState,
        lists,
      }}
    >
      <Modal
        open={true}
        onClose={cancelModal}
      >
        <ModalHeader title="You are about to delete your lists"/>
        <ModalBody>
          <ListDeleteModalBody/>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size="medium" className="align-self-end">
            <ListDeleteModalActionsBar />
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </DeleteModalContext.Provider>
  )
}

ListDeleteModal.propTypes = {
  onDeleteLists: PropTypes.func.isRequired,
  cancelModal: PropTypes.func.isRequired,
  resetCategory: PropTypes.func.isRequired,
  selectedLists: PropTypes.instanceOf(Map).isRequired,
}

export default ListDeleteModal;
export { DeleteModalContext };
