import Icon from "@javascript/reactComponents/lds/Icon";
import ToastBanner from "../ToastBanner";
import Heading from "@javascript/reactComponents/lds/typography/Heading";

const ListCreateInProgress = ({ listName }) => {
  return (
    <>
      <div className="status">
        <div className="create-happy-face-background">
          <Icon
            name="happy"
            style={{ height: 50, width: 50 }}
            className="icon-background"
          />
        </div>
        <Heading size="h2">
          Generating your list...
        </Heading>
        <span
          className={"type-body-medium"}
        >
          {listName}
        </span>
      </div>
      <ToastBanner
        title="Taking too long?"
        variant="info"
        className="mock-banner-info"
      >
        You can close this window at any time and we will generate your lists in
        the background for you. Access all your generated lists by visiting{" "}
        <b>Lists</b>.
      </ToastBanner>
    </>
  );
};

export default ListCreateInProgress;
