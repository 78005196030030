import React, { useContext } from "react";
import Button from "../../lds/Button";
import { DeleteModalContext } from "./ListDeleteModal";

const CloseButton = () => {
  const { cancelModal, resetCategory, deleteState, lists } = useContext(DeleteModalContext);
  return (
    <Button
      size="medium"
      variant="primary"
      id="close"
      onClick={() => {
        if (deleteState === "success" && lists.length === 0) {
          resetCategory();
        }
        cancelModal();
      }}
    >
      Close
    </Button>
  )
}

export default CloseButton;
