import Icon from "@javascript/reactComponents/lds/Icon";
import Heading from "@javascript/reactComponents/lds/typography/Heading";

const ListCreateSuccess = ({ listName }) => {
  return (
    <div className="status">
      <div
        className="delete-checkmark-background"
      >
        <Icon
          name="checkmark"
          style={{ height: 50, width: 50 }}
          className="icon-background"
        />
      </div>

      <Heading size={"h2"}>
        List Generated Successfully!
      </Heading>

      <span className={"type-body-medium"}>{listName}</span>
    </div>
  );
};

export default ListCreateSuccess;
