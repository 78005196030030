import _ from "lodash";
import Heading from "@javascript/reactComponents/lds/typography/Heading";
import ColleaguesListRow from "./ColleaguesListsRow";

export default function ColleaguesListsSection({ letter, bucket }) {
  // Group bucket by user
  const groupedByUserId = _.groupBy(bucket, (entry) => entry.user.id);

  return (
    <div className="colleagues-list-group">
      <Heading size="h2">{letter}</Heading>
      <hr />

      {Object.keys(groupedByUserId).map((userId, index) => (
        <ColleaguesListRow
          key={userId}
          evenChild={index % 2 === 0}
          lists={groupedByUserId[userId]}
        />
      ))}
    </div>
  );
}
