import CategoryDropdown from "./CategoryDropdown";

const CategorySelectionSection = () => {
  const styles = {
    backgroundColor: "#F1F2F4",
    borderRadius: "4px",
    margin: "32px 0",
    padding: "24px 32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }

  return (
    <div style={styles}>
      <span className={"type-body-bold-medium"}>Choose List Type</span>
      <CategoryDropdown />
    </div>
  )
}

export default CategorySelectionSection;
