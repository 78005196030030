import React, { useContext } from "react";
import { TableContext } from "./ListTable";
import ListRowChip from "./ListRowChip";

function ListTableRow({ list }) {
  const { editable, onListSelected, categories, selectedRows } =
    useContext(TableContext);
  let editColumn = null;

  if (editable) {
    editColumn = (
      <td className="text-center" style={{ verticalAlign: "middle" }}>
        <label className="list_delete_label" htmlFor={list.id}>
          <input
            id={list.id}
            type="checkbox"
            className="fancy_checkbox alert_checkbox"
            checked={selectedRows.has(list.id)}
            onClick={() => onListSelected(list)}
            onChange={() => {}}
          />
        </label>
      </td>
    );
  }

  return (
    <tr>
      <td style={{ verticalAlign: "middle" }}>
        <div className="name-column">
          <ListRowChip label={list.type_ref} />
          <a href={`/lists/${list.id}`} className="name-column-text">
            {list.name}
          </a>
        </div>
      </td>
      <td
        className="text-center entries-count-text"
        style={{ verticalAlign: "middle" }}
      >
        {list.entity_count}
      </td>
      {editColumn}
    </tr>
  );
}

export default ListTableRow;
