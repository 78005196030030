import { Modal } from "@javascript/reactComponents/lds/modal/Modal";
import GDPRModal from "./GDPRModal";

const ZoominfoBuyContact = createReactClass({
  getInitialState: function () {
    return {
      privacy_satisfied: true,
      gdpr_submitted: false,
      gdprModalOpen: false,
      gdprCountry: "",
    };
  },

  isApolloActive: function(){
    return Zymewire.user.feature_tags.includes("can_use_apollo_contact_search");
  },

  hasCredits: function() {
    return this.props.credits_available > 0;
  },

  tooltipText: function(){
    var tooltipText = null;

    if (this.isApolloActive()) {
      return "";
    }

    if (!this.hasCredits()) {
      tooltipText = "Sorry, you have no credits left for this month."
    } else if (this.props.showPhone && this.props.showEmail) {
      tooltipText = "Use a single credit to see both their email address and phone number";
    } else if (this.props.showEmail){
      tooltipText = "Use a credit to see their email address";
    } else {
      tooltipText = "Reveal phone number without using credits";
    }

    return tooltipText;
  },

  buyContact: function(){
    if(this.isApolloActive() || this.hasCredits()){
      if(this.state.privacy_satisfied === false){
        var zi_country_hack = null;
        if(this.props.LocalAddress){
          zi_country_hack =  this.props.LocalAddress.country;
        }

        this.setState({
          gdprCountry: zi_country_hack,
          gdprModalOpen: true,
        });
      }
      else{
        $("body").addClass("wait");
        var locAddress;
        if(this.props.LocalAddress){
          var zi_country_hack =  this.props.LocalAddress.country;
          var addressString = _.uniq(_.compact([this.props.LocalAddress.city, this.props.LocalAddress.state, zi_country_hack]));
          locAddress = addressString.join(", ");
        }
        $(this.refs.revealButton).text('Sending...')
        setTimeout(function(){
          ContactSearchAction.buyContact(this.props.PersonId, locAddress, this.state.gdpr_submitted, this.props.provider);
        }.bind(this), 1000);
      }
    }
  },
  confirmPrivacy: function(e){
    this.setState({privacy_satisfied:true,gdpr_submitted:true}, this.buyContact);
    this.closeGdprModal();
  },
  closeGdprModal: function() {
    this.setState({gdprModalOpen: false});
  },
  componentDidMount:function(){
    var privacy_havens = ['Austria','Belgium','Bulgaria','Croatia','Cyprus','Czech Republic','Denmark','Estonia','Finland','France','Germany','Greece','Hungary','Ireland','Italy','Latvia','Lithuania','Luxembourg','Malta','Netherlands','Poland','Portugal','Romania','Slovakia','Slovenia','Spain','Sweden','United Kingdom'];
    if(this.props.LocalAddress){
      var zi_country_hack =  this.props.LocalAddress.country;
      const gdprIndex = privacy_havens.findIndex(item => zi_country_hack.toLowerCase() === item.toLowerCase());
      if(gdprIndex !== -1){
        this.setState({privacy_satisfied: false});
      }
    }
  },
  render: function () {

    const class_names = ['button-small button-primary'];

    if(this.isApolloActive() || this.hasCredits()){
      class_names.push('rspec_reveal_contact');
    }
    else {
      class_names.push('button-disable');
    }

    return (
      <td>
        <button ref="revealButton" type="submit" className={class_names.join(' ')} onClick={this.buyContact} title={this.tooltipText()}>Reveal</button>
        <Modal size="large" open={this.state.gdprModalOpen} onClose={this.closeGdprModal}>
          <GDPRModal location={this.state.gdprCountry} onConfirm={this.confirmPrivacy} onClose={this.closeGdprModal} />
        </Modal>
      </td>
    );
  }
});

export default ZoominfoBuyContact;