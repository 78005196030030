import React, { useContext } from "react";
import Icon from "../../../lds/Icon";
import { DeleteModalContext } from "../ListDeleteModal";
import ToastBanner from "../ToastBanner";
import Heading from "@javascript/reactComponents/lds/typography/Heading";
import BannerHeading from "@javascript/reactComponents/lds/notifications/BannerHeading";
import BannerBody from "@javascript/reactComponents/lds/notifications/BannerBody";
import Banner from "@javascript/reactComponents/lds/notifications/Banner";

const ListDeleteModalDeletingBody = () => {
  const { selectedLists } = useContext(DeleteModalContext);

  return (
    <div className="status flex-col">
      <Icon name="special-destruct" />

      <Heading size="h2">
        Deleting {selectedLists.size} list{selectedLists.size === 1 ? "" : "s"}...
      </Heading>

      <Banner variant="info">
        <BannerHeading>Taking too long?</BannerHeading>
        <BannerBody>
          <p>
            You can close this window at any time and we will delete your lists in
            the background for you. Access all your generated lists by visiting{" "}
            <b>Lists</b>.
          </p>
        </BannerBody>
      </Banner>
    </div>
  );
};

export default ListDeleteModalDeletingBody;
